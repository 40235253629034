import { Fragment } from "react";
import gcLogoWhite from "../assets/images/gc-icon-white.png";
import DonationForm from "./donation_form";
import { ReactComponent as Alarm } from "../assets/images/alarm.svg";
import gcLogo from "../assets/images/gc-text-logo-color.png";

const DetailsSection = ({
  contributionDetails,
  donationsTotal,
  collectionId,
}) => {
  let image = contributionDetails.image ?? null;
  let style = {};

  if (image !== null) {
    style = {
      backgroundColor: "#6583fd",
      backgroundImage: `url('${image}')`,
      backgroundPosition: `center`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    };
  }

  return (
    <div className="contribution">
      <div className="contribution__image" style={style}>
        {!image && (
          <img
            src={gcLogoWhite}
            alt="GoContribute"
            title={contributionDetails.name}
          />
        )}
      </div>
      <div className="contribution__box">
        <Fragment>
          <ContributionDetails
            details={contributionDetails}
            donationsTotal={donationsTotal}
          />

          <DonationForm
            creatorId={contributionDetails.creatorId}
            collectionId={collectionId}
            contributionName={contributionDetails.name}
          />
        </Fragment>
        <p className="powered-by">
          Powered by
          <a
            href="https://www.gocontribute.com"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="logo"
              src={gcLogo}
              alt="GoContribute"
              title="Powered by GoContribute"
            />
          </a>
        </p>
      </div>
    </div>
  );
};

export default DetailsSection;

const ContributionDetails = ({ details, donationsTotal }) => {
  let title = details.name;
  let description = details.description;
  // let location = details.location;

  return (
    <div className="contribution__details">
      <h1 className="contribution__title">{title}</h1>

      {details.displayAmount && (
        <div className="contribution__total">
          <p>
            £
            {donationsTotal.toLocaleString(navigator.language, {
              minimumFractionDigits: 2,
            })}
          </p>
          {details.goal !== 0 && (
            <p>
              of{" "}
              <span>
                £
                {details.goal.toLocaleString(navigator.language, {
                  minimumFractionDigits: 2,
                })}
              </span>{" "}
              raised
            </p>
          )}
          {details.goal === 0 && <p>raised so far!</p>}
        </div>
      )}

      <TimeBox isActive={details.isActive} time={details.datetime} />

      {description !== "" && (
        <div className="contribution__description">
          <p>Description</p>
          <p>{description}</p>
        </div>
      )}

      {/* {location !== "" && (
        <div className="contribution__location">
          <span>Where</span>
          <p>{location}</p>
        </div>
      )} */}
    </div>
  );
};

const TimeBox = ({ time, isActive }) => {
  const date1 = Date.now();

  //Have to format date so we can set time to be end of day just incase it didn't happen in app
  //Then have to parse back to unix for comparison
  const date2 = new Date(time.seconds * 1000);
  // console.log(date2);
  const datestring =
    date2.getFullYear() +
    "-" +
    (date2.getMonth() < 9
      ? "0" + (date2.getMonth() + 1)
      : date2.getMonth() + 1) +
    "-" +
    (date2.getDate() < 9 ? "0" + date2.getDate() : date2.getDate()) +
    "T23:59:59Z";
  const unixDate2 = Date.parse(datestring);

  const diffTime = Math.abs(unixDate2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return (
    <div className="contribution__time-box">
      {isActive && diffDays <= 1 && (
        <div className="time-ending">
          <Alarm /> <p>Ends tomorrow!</p>
        </div>
      )}
      {isActive && diffDays > 1 && (
        <div className="time-row">
          <span>
            <Alarm />
            <span>Time Remaining</span>
          </span>
          <span className="days">{diffDays} days</span>
        </div>
      )}
      {!isActive && <p className="time-ended">Event has ended</p>}
    </div>
  );
};
