import { db } from "../config/firebase";
import {
  collection,
  addDoc,
  getDoc,
  getDocs,
  doc,
  serverTimestamp,
} from "firebase/firestore";

const createPaymentIntent = async (options) => {
  try {
    let response = await window.fetch(
      `https://us-central1-gocontribute-cd04c.cloudfunctions.net/stripePaymentIntentRequest`,
      {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods":
            "GET, PUT, POST, DELETE, HEAD, OPTIONS",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(options),
      }
    );

    let intentResponse = await response.json();
    // console.log(intentResponse);
    if (intentResponse["success"] !== true) {
      throw Error(intentResponse["error"]);
    }

    return intentResponse["paymentIntent"];
  } catch (error) {
    console.error("Error fetching intent url", error.message);
  }
};

const getContribution = async (contributionId) => {
  try {
    // const contributionsCollection = db.collection('contributions').doc(contributionId).g;
    // console.log(contributionsCollection);
    // const cityList = contributionSnapshot.docs.map(doc => doc.data());
    // return cityList;

    const docRef = doc(db, "contributions", contributionId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      // console.log("Document data:", docSnap.data());

      return docSnap.data();
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }
  } catch (error) {
    console.error(error);
    return "Error fetching contribution";
  }
};

const getAllDonations = async (contributionId) => {
  const docRef = collection(
    db,
    "contributions",
    contributionId,
    "contributors"
  );
  const docSnap = await getDocs(docRef);
  let donationsTotal = 0;

  docSnap.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    let data = doc.data();
    donationsTotal += data.amount;
    // console.log(doc.id, " => ", doc.data());
  });

  // console.log(donationsTotal);
  return donationsTotal;
  // return db.collection('contributions').doc(contributionId).collection('contributors').onSnapshot((snapshot) => {
  //   const postData = [];
  //   snapshot.forEach((doc) => postData.push({ ...doc.data(), id: doc.id }));
  //   console.log(postData);
  // });
};

const saveDonation = async (amount, name, email, message, contributionId) => {
  // Add a new message entry to the Firebase database.
  try {
    await addDoc(
      collection(db, "contributions", contributionId, "contributors"),
      {
        name: name,
        amount: amount,
        email: email,
        message: message,
        createdAt: serverTimestamp(),
      }
    );
  } catch (error) {
    console.error("Error writing donation to database", error);
  }
};

const getUserStripeId = async (userId) => {
  try {
    const docRef = doc(db, "users", userId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      // console.log("Document data:", docSnap.data());

      return docSnap.data().stripeId;
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }
  } catch (error) {
    console.error(error);
    return "Error fetching user";
  }
};

const getUserFCMToken = async (userId) => {
  try {
    const docRef = doc(db, "users", userId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      // console.log("Document data:", docSnap.data());

      return docSnap.data().regToken;
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }
  } catch (error) {
    console.error(error);
    return "Error fetching user";
  }
};

const sendNotification = async (deviceToken, title) => {
  try {
    let response = await window.fetch(
      `https://us-central1-gocontribute-cd04c.cloudfunctions.net/sendNotification`,
      {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods":
            "GET, PUT, POST, DELETE, HEAD, OPTIONS",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          deviceToken: deviceToken,
          title: title,
        }),
      }
    );

    let result = await response.json();
    // console.log(result);
    if (result["success"] !== true) {
      throw Error(result["error"]["code"] + ": " + result["error"]["code"]);
    }

    // console.log(result["response"]);
  } catch (error) {
    console.error("Error sending notification", error);
  }
};

const contributionApi = {
  createPaymentIntent,
  getContribution: getContribution,
  saveDonation: saveDonation,
  getAllDonations: getAllDonations,
  getUserStripeId: getUserStripeId,
  sendNotification: sendNotification,
  getUserFCMToken: getUserFCMToken,
};

export default contributionApi;
