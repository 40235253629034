import React, { Fragment, useEffect, useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
  Elements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "react-stripe-js";
import contributionApi from "./repository";
import gcLogo from "../assets/images/gc-icon.png";
import DropdownField from "../assets/components/dropdown";

const currentUrl = window.location.href;
const testUrl = "https://test.gocontribute.com/";
let stripePubKey =
  "pk_live_51MC8goIUWG9ID5Cf9N1Fwr1xMxOMZRvPFhPSvAGA7JOaAfg7FjKnXm6HqTLamgqfkKrl3OTDtuWHPPAYlW3AnQNF00WyqFJWxG";

if (currentUrl.startsWith(testUrl)) {
  stripePubKey =
    "pk_test_51MC8goIUWG9ID5CfT7m9LXnfq7DVgZFQ6Qk2qYPtl59MwHcB4r6kLBkDpVSf2eqWIoAhzCfMTy13ophq6gvGIf4G00qsTNct17";
}

let stripePromise = await loadStripe(stripePubKey);

export default function DonationForm({
  creatorId,
  collectionId,
  contributionName,
}) {
  const [amount, setAmount] = useState(4);
  const [accountId, setAccountId] = useState(null);
  const [showCustom, setShowCustom] = useState(false);
  const [showPaymentModule, setShowPaymentModule] = useState(false);
  const [contributorName, setContributorName] = useState();
  const [contributorEmail, setContributorEmail] = useState("");
  const [contributorMessage, setContributorMessage] = useState("");
  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [shareLinkText, setShareLinkText] = useState("Share");
  const [checked, setChecked] = React.useState(true);

  useEffect(() => {
    const getAndSetAccountId = async () => {
      try {
        const stripeId = await contributionApi.getUserStripeId(creatorId);
        setAccountId(stripeId);
      } catch (error) {
        console.error(error);
      }
    };

    getAndSetAccountId();
  }, [creatorId]);

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setError(null);

    if (accountId === null || accountId === "") {
      setError("No account id found. Please contact an admin");
      return;
    }

    setContributorName(ev.target.name.value);
    setContributorEmail(ev.target.email.value);
    setContributorMessage(ev.target.message.value);

    setProcessing(true);
    setShowPaymentModule(true);
  };

  const shareLinkClick = async () => {
    try {
      navigator.clipboard.writeText(
        `https://collection.gocontribute.com/${collectionId}`
      );
      setShareLinkText("Link copied!");
    } catch (error) {
      console.error("could not copy link!");
      setShareLinkText("Unable to copy link.");
    } finally {
      setTimeout(() => {
        setShareLinkText("Share");
      }, 2000);
    }
  };

  const sendNotification = async () => {
    try {
      let deviceToken = await contributionApi.getUserFCMToken(creatorId);
      await contributionApi.sendNotification(
        deviceToken,
        `Someone donated to ${contributionName}`
      );
    } catch (error) {
      console.error("Unable to send notification", error);
    }
  };

  const renderSuccess = () => {
    sendNotification();

    return (
      <div className="success-section">
        <img
          className="success-section__logo"
          src={gcLogo}
          alt="GoContribute"
          title="Powered by GoContribute"
        />
        <h1 className="success-section__title">
          Thanks for contributing to <span>{contributionName}</span>
        </h1>
        <p className="success-section__blurb">
          Continue to help this event by sharing it with your friends!
        </p>
        <div className="success-section__buttons">
          <button className="button button--wide" onClick={shareLinkClick}>
            {shareLinkText}
          </button>
          <span
            className="donate-again"
            onClick={() => {
              setSucceeded(false);
              setChecked(true);
            }}
          >
            Donate again?
          </span>
        </div>
      </div>
    );
  };

  const handlePaymentSuccess = () => {
    setAmount(0);
    setProcessing(false);
    setShowCustom(false);
    setShowPaymentModule(false);
    setSucceeded(true);
  };

  // const handleChange = () => {
  //   setChecked(!checked);
  // };

  const calcAmount = () => {
    if (amount < 5) {
      return "Minimum amount is £5";
    }

    if (!checked) {
      return `Contribute £${amount.toLocaleString(navigator.language, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`;
    }

    let amountPlusFee = amount * 1.05;
    return `Contribute £${amountPlusFee.toLocaleString(navigator.language, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  };

  const renderForm = (contributionName) => {
    return (
      <Fragment>
        <h3 className="heading">Contribute to {contributionName}</h3>
        <form onSubmit={handleSubmit}>
          {!showCustom && (
            <div className="donation-btns">
              <div
                className={`donationBtn ${
                  amount === 10 ? "donationBtn--active" : ""
                }`}
                onClick={() => setAmount(10)}
              >
                £10
              </div>
              <div
                className={`donationBtn ${
                  amount === 15 ? "donationBtn--active" : ""
                }`}
                onClick={() => setAmount(15)}
              >
                £15
              </div>
              <div
                className={`donationBtn ${
                  amount === 20 ? "donationBtn--active" : ""
                }`}
                onClick={() => setAmount(20)}
              >
                £20
              </div>
              <div
                className={`donationBtn donationBtn--custom`}
                onClick={() => {
                  setShowCustom(true);
                  setAmount(0);
                }}
              >
                Custom
              </div>
            </div>
          )}

          {showCustom && (
            <div className="donation-custom">
              <input
                type="number"
                id="custom-amount"
                name="custom"
                placeholder="Amount"
                className="custom-input"
                step=".01"
                onChange={(e) => {
                  let val = e.target.value;
                  if (val > 0) {
                    setAmount(parseFloat(val));
                  } else {
                    setAmount(0);
                  }
                }}
              />{" "}
              <span
                className="cancel-custom"
                onClick={() => {
                  setShowCustom(false);
                  setAmount(0);
                }}
              >
                &times;
              </span>
            </div>
          )}

          <div className="donation-inputs">
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Name"
              autoComplete="cardholder"
              required
            />
            <input
              type="text"
              id="email"
              name="email"
              placeholder="Email"
              autoComplete="email"
            />
            <textarea id="message" name="message" placeholder="Message" />
          </div>

          {/* <div className="donation-fee">
            <h2>Help keep costs low</h2>
            <label>
              <div className="checkContainer" onChange={handleChange}>
                <input
                  type="checkbox"
                  checked={checked}
                  onChange={handleChange}
                />
                <span className="checkmark"></span>
              </div>
              <span className="fee-text">
                We charge a small fee to help us keep this platform operational
                and the cost to the organiser low. However, you are free to not
                pay this.
              </span>
            </label>
          </div> */}

          <div className="donation-fee-section">
            {amount > 5 && (
              <div className="fee">
                <p>Platform Fee</p>
                <p>£{(amount * 0.05).toFixed(2)}</p>
              </div>
            )}
            <DropdownField title="Why do we charge a fee?">
              <p>🚗 Save you a trip to the shops 🚕 🚌 🚂</p>
              <p>⌚️On average save you 30+ minutes thinking what to buy 🙈</p>
              <p>
                🌲 You save a journey and it’s another step in protecting our
                planet 🌍
              </p>
              <p>
                💷 The banks really know how to sting us so this helps cover
                those costs too🐝
              </p>
            </DropdownField>
          </div>

          {error && <div className="stripe-errors">{error}</div>}

          <button
            className={`button button--lg ${
              processing ? "button--loading" : ""
            }`}
            disabled={amount < 5}
          >
            {calcAmount()}
          </button>
        </form>
        {showPaymentModule && (
          <Elements
            stripe={stripePromise}
            options={{
              mode: "payment",
              currency: "gbp",
              amount: checked ? amount * 1.05 * 100 : amount * 100,
            }}
          >
            <StripeModule
              amount={amount * 100}
              isChecked={checked}
              accountId={accountId}
              successHandler={handlePaymentSuccess}
              collectionId={collectionId}
              contributorName={contributorName}
              contributorEmail={contributorEmail}
              contributorMsg={contributorMessage}
              contributionId={collectionId}
              cancelHandler={() => {
                setProcessing(false);
                setShowPaymentModule(false);
              }}
            />
          </Elements>
        )}
      </Fragment>
    );
  };

  return (
    <div className="checkout-form">
      {succeeded ? renderSuccess() : renderForm(contributionName)}
    </div>
  );
}

const StripeModule = ({
  amount,
  isChecked,
  accountId,
  successHandler,
  collectionId,
  contributorName,
  contributionId,
  contributorEmail = "",
  contributorMsg = "",
  cancelHandler,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [canSubmit, setCanSubmit] = useState(false);

  const createIntent = async () => {
    try {
      const response = await contributionApi.createPaymentIntent({
        payment_method_types: ["card"],
        amount: isChecked ? amount * 1.05 : amount,
        accountId: accountId,
        appFee: amount * 0.05,
        collectionId: collectionId,
        email: contributorEmail,
      });

      console.log(response);
      return response;
    } catch (error) {
      console.error("Error creating intent", error.message);
      setError(error.message);
    }

    return null;
  };

  const submitPayment = async (ev) => {
    ev.preventDefault();
    ev.stopPropagation();

    if (!stripe) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setProcessing(true);
    setError(null);

    try {
      // Trigger form validation and wallet collection
      const { error: submitError } = await elements.submit();
      if (submitError) {
        throw submitError;
      }
      // ev.target.name.value
      // Create the PaymentIntent and obtain clientSecret
      const clientSecret = await createIntent();

      // Use the clientSecret and Elements instance to confirm the setup
      const { error } = await stripe.confirmPayment({
        elements,
        clientSecret,
        confirmParams: {
          return_url: `https://collection.gocontribute.com/${collectionId}/`,
        },
        // Uncomment below if you only want redirect for redirect-based payments
        redirect: "if_required",
      });

      if (error) {
        throw error;
      }

      await contributionApi.saveDonation(
        amount / 100,
        contributorName,
        contributorEmail,
        contributorMsg,
        contributionId
      );

      setError(null);
      successHandler();
      setProcessing(false);
      // setMetadata(payload.paymentIntent);
    } catch (error) {
      console.log(error.message);
      setError(error.message);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <div className="payment-module">
      <form onSubmit={submitPayment}>
        <h2 className="heading">Pay with Stripe</h2>
        <PaymentElement
          className="stripe-element"
          onChange={(e) => {
            console.log(e);
            if (e.complete === true) {
              setCanSubmit(true);
            } else {
              if (canSubmit === true) {
                setCanSubmit(false);
              }
            }
          }}
        />{" "}
        {error && <div className="stripe-errors">{error}</div>}
        <div className="payment-module__buttons">
          <button
            className={`button button--lg ${
              processing ? "button--loading" : ""
            }`}
            disabled={!canSubmit || !stripe}
          >
            Pay
          </button>
          {!processing && (
            <span className="cancel-button" onClick={cancelHandler}>
              Cancel
            </span>
          )}
        </div>
      </form>
    </div>
  );
};
