import { useState } from "react";

const DropdownField = ({ title, children }) => {
  const [open, setOpen] = useState(false);
  const [height, setHeight] = useState(0);

  const handleOpen = () => {
    setOpen(!open);
    if (open) {
      setHeight(0);
    } else {
      setHeight(document.querySelector(".dropdown__content").scrollHeight + 32);
    }
  };

  return (
    <div className={`dropdown ${open ? "dropdown--active" : ""}`}>
      <div onClick={handleOpen} className="dropdown__title">
        {title}
        <span className="close"></span>
      </div>
      <div className="dropdown__content" style={{ height: `${height}px` }}>
        {children}
      </div>
    </div>
  );
};

export default DropdownField;
