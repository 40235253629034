import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import contributionApi from "./repository";
import DetailsSection from "./details";
import ErrorScreen from "../utils/error-screen";
import gcLogo from "../assets/images/gc-text-logo-color.png";

const ContributionPage = () => {
  const params = useParams();
  let [isLoading, setIsLoading] = useState(true);
  let [loadingErrors, setLoadingErrors] = useState(null);
  let [collectionData, setCollectionData] = useState(null);
  let [donationsTotal, setDonationsTotal] = useState(0);

  useEffect(() => {
    async function fetchData() {
      //   console.log(params.collection_id);
      //Bail if no collectionid
      if (params.collection_id === "") return;
      let collection_id = params.collection_id.replace(/(<([^>]+)>)/gi, "");
      //   let collection_id = "FVSwoXGu9zu7kLzT3gxm".replace(/(<([^>]+)>)/gi, "");

      try {
        const data = await contributionApi.getContribution(collection_id);
        const contributionsData = await contributionApi.getAllDonations(
          collection_id
        );
        // console.log(data);
        if (data) {
          setCollectionData(data);
          setDonationsTotal(contributionsData);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setLoadingErrors(`Error getting contribution: ${error}`);
        setCollectionData(null);
      }
    }

    fetchData();
  }, [params.collection_id]);

  return (
    <div className="contribution-page">
      {isLoading && (
        <div className="loading-section">
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <p className="loading-section__text">Fetching contribution...</p>
        </div>
      )}
      {loadingErrors != null && (
        <ErrorScreen
          errorTitle="Error loading Contribution"
          errorMessage={loadingErrors}
        />
      )}
      {collectionData != null && (
        <Fragment>
          {/* <HelmetMeta
            name={collectionData.name}
            description={collectionData.description}
            imageUrl={collectionData.image}
          /> */}
          <DetailsSection
            contributionDetails={collectionData}
            donationsTotal={donationsTotal}
            collectionId={params.collection_id}
          />
        </Fragment>
      )}
      {!isLoading && collectionData == null && (
        <div className="no-data">
          <img
            className="unknown__logo"
            src={gcLogo}
            alt="GoContribute"
            title="Powered by GoContribute"
          />
          <h2 className="heading">No contribution found</h2>
          <p>Nothing could be found for contribution {params.collection_id}</p>
        </div>
      )}
    </div>
  );
};

export default ContributionPage;
