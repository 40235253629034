import { Routes } from "react-router-dom";
import { Route, BrowserRouter } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import ContributionPage from "./contributions/contribution";
import NoPage from "./noPage";

function App() {
  <Helmet>
    <title>GoContribute</title>
    <meta
      name="description"
      content="A new way to contribute with GoContribute"
    />
  </Helmet>;
  return (
    // <HelmetProvider>
    <BrowserRouter>
      <Routes>
        <Route path="/">
          {/* <Route exact path='/' element={<ContributionPage />} /> */}
          <Route exact path="/:collection_id" element={<ContributionPage />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
    // </HelmetProvider>
  );
}

export default App;
