import gcLogo from "./assets/images/gc-icon.png";

const NoPage = () => {
  return (
    <div className="unknown">
      <img
        className="unknown__logo"
        src={gcLogo}
        alt="GoContribute"
        title="Powered by GoContribute"
      />
      <p>We searched and searched yet alas.</p>
      <p>Nothing was found...</p>
    </div>
  );
};

export default NoPage;
