import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDQjIFVoCDdsODL_mm_I9RJLGWbVuYSd8M",
  authDomain: "gocontribute-cd04c.firebaseapp.com",
  projectId: "gocontribute-cd04c",
  storageBucket: "gocontribute-cd04c.appspot.com",
  messagingSenderId: "960309120423",
  appId: "1:960309120423:web:f2d45f2abf6aa35fad7a82",
  measurementId: "G-VNNXS4C07E",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
export const db = getFirestore(app);
